import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { SYMPTOM_TRACKER, TRACK_SYMPTOM } from "../../constants/DashboardTranslation";

import { useHistory, Link } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { format, compareDesc } from "date-fns";

import theme from "../../index.scss";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import { sendWarnEmailToProviderAndTP } from "../../api/helpReq";
import { postTrackSymptom } from "../../api/TBIRequests";
import { set } from "lodash";

const SymptomTracker = ({
  currentTrackingStep,
  topSymptoms,
  date,
  user,
  symptom,
  trackedData,
  skipTrackingStep,
  saveTrackingStep,
  loadTrackedData,
}) => {
  const history = useHistory();

  const [showNotes, setShowNotes] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showImprove, setShowImprove] = useState(false);
  const [showBigImprove, setShowBigImprove] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext)
  const [severitySymptom, setSeveritySymptom] = useState(0);

  const { register, handleSubmit, errors, setError, reset } = useForm();

  let severityColor;
  if (severitySymptom <= 33) {
    severityColor = '#28a745'; // green
  } else if (severitySymptom <= 66) {
    severityColor = '#ffc107'; // yellow
  } else {
    severityColor = '#dc3545'; // red
  }

  const dataset = trackedData.filter((item) => item.factor === symptom.factor);

  let prevIntensity;
  if (dataset && dataset.length > 0) {
    for (let i = dataset.length - 1; i >= 0; i--) {
      let isPrevDate = compareDesc(
        new Date(dataset[i].symptom_date),
        new Date(date)
      );

      if (isPrevDate === 1 && !prevIntensity) {
        prevIntensity = dataset[i].severity;
      }
    }
  } else {
    prevIntensity = null;
  }

  useEffect(() => {
    if (prevIntensity) {
      const currIntensity = parseInt(severitySymptom, 10);

      // reset all flags first
      setShowNotes(false);
      setShowWarning(false);
      setShowImprove(false);
      setShowBigImprove(false);

      if (currIntensity >= prevIntensity + 10 && currIntensity < prevIntensity + 30) {
        setShowNotes(true);
      } else if (currIntensity >= prevIntensity + 30) {
        setShowWarning(true);
      } else if (currIntensity + 30 <= prevIntensity && currIntensity !== 0) {
        setShowBigImprove(true);
      } else if (currIntensity + 10 <= prevIntensity && currIntensity !== 0) {
        setShowImprove(true);
      }
    }
  }, [prevIntensity, severitySymptom]);

  const onSubmit = async (data) => {
    setSubmitting(true);

    data.severitySymptom = severitySymptom;

    if ((showNotes || showImprove || showBigImprove || showWarning) && !data.describeSymptom) {
      setError("describeSymptom", {
        type: "err",
        message: SYMPTOM_TRACKER[language].missing_note,
      });
      setSubmitting(false);
      return;
    }

    // Send email alert if there's a 30% increase
    if (showWarning) {
      const picked = { factor: symptom.factor };
      const processedData = [
        {
          ...data,
          symptom: picked,
          datetimeOfSymptom: format(date, "yyyy-MM-dd"),
        },
      ];
      try {
        await sendWarnEmailToProviderAndTP({
          patientId: patient.patientId,
          data: processedData,
        });
      } catch (err) {
        console.warn("sendProviderWarnEmail error", err);
      }
    }

    const picked = {
      factor: symptom.factor,
      category: symptom.category,
      subcategory: symptom.subcategory,
    };
    const processedData = [
      {
        ...data,
        symptom: picked,
        datetimeOfSymptom: format(date, "yyyy-MM-dd"),
        hadSymptom: true,
      },
    ];
    try {
      await postTrackSymptom({
        patientId: patient.patientId,
        data: processedData,
      });
      reset();
      setSeveritySymptom(0);
      saveTrackingStep();
      loadTrackedData();
    } catch (err) {
      history.push("/oops");
    }
  };

  if (!symptom || topSymptoms.length == 0) {
    return (
      <div>
        {SYMPTOM_TRACKER[language].no_symptoms[0]}
        <Link
          to={{
            pathname: "laterSymptoms",
            state: { fromPatientControl: false, fromPatientDashboard: true },
          }}
        >
          {SYMPTOM_TRACKER[language].no_symptoms[1]}
        </Link>
      </div>
    );
  }

  return (
    <>
      <h6 className="d-flex justify-content-between">
        <span>
          {patient.userPatientRelationshipType == 'self'
            ? TRACK_SYMPTOM[language].patient
            : TRACK_SYMPTOM[language].non_patient[0] + patient.firstName + TRACK_SYMPTOM[language].non_patient[1]
          }

        </span>
        <span>{format(date, "LLL d, yyyy")}</span>
      </h6>
      <div
        className="p-3 m-3"
        style={{
          borderRadius: "5px",
          boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.75)",
        }}
      >
        <div className="d-flex justify-content-center">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "50%",
              borderRadius: "20px",
              backgroundColor: dark ? theme.darkModePrimary : theme.primary,
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
            }}
          >
            {symptom.factor}
          </h6>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Label style={{ width: "100%" }}>
            {SYMPTOM_TRACKER[language].intensity_level.label}{severitySymptom}
            <Form.Control
              name="severitySymptom"
              ref={register}
              type="range"
              min="0"
              max="100"
              className="severity-range"
              value={severitySymptom}
              onChange={(e) => setSeveritySymptom(e.target.value)}
              style={{
                // track color changes based on increase in symptom severity
                '--severity-color': severityColor,
                '--severity-val': `${severitySymptom}%`
              }}
            />
            <div
              style={{ fontSize: "14px" }}
              className="d-flex justify-content-between"
            >
              <span>
                {SYMPTOM_TRACKER[language].intensity_level.no}
              </span>
              <span style={{ textAlign: "right" }}>
                {SYMPTOM_TRACKER[language].intensity_level.worst}
              </span>
            </div>
          </Form.Label>

          {(showNotes || showImprove || showBigImprove || showWarning) && (
            <>
              {showWarning ? (
                <div
                  className="p-3"
                  style={{ border: "1px solid red", borderRadius: "5px" }}
                >
                  {user.userType == "caregiver"
                    ? SYMPTOM_TRACKER[language].messages.big_increase.caregiver[0] +
                    patient.firstName +
                    SYMPTOM_TRACKER[language].messages.big_increase.caregiver[1] +
                    symptom.factor +
                    SYMPTOM_TRACKER[language].messages.big_increase.caregiver[2]

                    : SYMPTOM_TRACKER[language].messages.big_increase.patient[0] +
                    symptom.factor +
                    SYMPTOM_TRACKER[language].messages.big_increase.patient[1]
                  }
                  <Link
                    to={{
                      pathname: "/warning",
                      state: { symtom: symptom.factor },
                    }}
                  >
                    {SYMPTOM_TRACKER[language].messages.big_increase.learn_more}
                  </Link>
                </div>
              ) : showBigImprove ? (
                <div
                  className="p-3"
                  style={{ border: "1px solid blue", borderRadius: "5px" }}
                >
                  {user.userType == "caregiver"
                    ? SYMPTOM_TRACKER[language].messages.big_improve.caregiver[0] +
                    patient.firstName +
                    SYMPTOM_TRACKER[language].messages.improve.caregiver[1]
                    : SYMPTOM_TRACKER[language].messages.big_improve.patient}
                </div>
              ) : showImprove ? (
                user.userType == "caregiver" ? (
                  <h6>
                    {SYMPTOM_TRACKER[language].messages.improve.caregiver[0]}
                    {patient.firstName}
                    {SYMPTOM_TRACKER[language].messages.improve.caregiver[1]}
                  </h6>
                ) : (
                  <h6>{SYMPTOM_TRACKER[language].messages.improve.patient}</h6>
                )
              ) : (
                <h6>
                  {SYMPTOM_TRACKER[language].messages.increase}
                </h6>
              )}

              <LabelledInput
                required
                label={SYMPTOM_TRACKER[language].note}
                name="describeSymptom"
                errors={errors}
                inputRef={register}
                inputAs="textarea"
              />
            </>
          )}

          <Form.Row
            style={{ flexGrow: "1", alignItems: "flex-end" }}
            className="d-flex justify-content-between"
          >
            <SubmitButton
              onClick={() => {
                reset();
                setSeveritySymptom(0);
                skipTrackingStep();
              }}
              style={{ height: "max-content" }}
              type="button"
            >
              {SYMPTOM_TRACKER[language].skip}
            </SubmitButton>
            {currentTrackingStep +
              1 +
              `/` +
              topSymptoms.length +
              SYMPTOM_TRACKER[language].total_symptoms}
            <SubmitButton
              style={{ height: "max-content" }}
              type="submit"
              disabled={submitting}
            >
              {SYMPTOM_TRACKER[language].save}
            </SubmitButton>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

export default SymptomTracker;
