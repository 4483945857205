import React, { useEffect, useState, useContext } from "react";
import { ThemeContext, PatientContext, InputVisibleContext, PreferredLanguageContext } from "../lib/main-context";
import { SALLIE_DASHBOARD, VERIFY_EMAIL, TOGGLE_MESSAGES, MAILBOX, TOGGLE_ALERTS } from "../constants/DashboardTranslation";

import { useHistory, useLocation } from "react-router-dom";
import "react-day-picker/lib/style.css";
import { Row, Col, Alert } from "react-bootstrap";

//components
import Sallie from "./Sallie";
import GoalList from "./DashboardComponents/GoalList";
import FeelingToday from "./DashboardComponents/FeelingToday";
import Calendar from "./DashboardComponents/Calendar";
import EditNotification from "./DashboardComponents/EditNotification";
import SymptomTracker from "./DashboardComponents/SymptomTracker";
import DaysFromInjury from "./DashboardComponents/DaysFromInjury";
import ComponentWrapper from "./DashboardComponents/ComponentWrapper";
import AdditionalNote from "./DashboardComponents/AdditionalNote";
import TriggerSelector from "./DashboardComponents/TriggerSelector";
import SuggestionBox from "./DashboardComponents/SuggestionBox";
import ThemeChanger from "./ThemeChanger";
import AddSymptoms from "./DashboardComponents/AddSymptoms";
import Charts from "./DashboardComponents/Charts";
import LoadingSpinner from "./LoadingSpinner";
import CurrentPatient from "./DashboardComponents/CurrentPatient";
import ReferPatientButton from "./DashboardComponents/ReferPatientButton";
import OnOffButtonMessages from "./DashboardComponents/OnOffButtonMessages";
import OnOffButtonAlert from "./DashboardComponents/OnOffButtonAlert";
import OnOffButtonReferralRecord from "./DashboardComponents/OnOffButtonReferralRecord";
import PatientStatus from "./DashboardComponents/PatientStatus";
import InviteProvider from "./DashboardComponents/InviteProvider";
import InviteTherapist from "./DashboardComponents/InviteTherapist";
import InvitePatient from "./DashboardComponents/InvitePatient";

//lib
import customizeMessages from "../lib/customizeMessages";
import useWindowSize from "../lib/useWindowSize";
import getLoggedDataToday from "../lib/getLoggedDataToday";

//api
import { getAdditionalNote } from "../api/TBIRequests";
import { getPatientRegistrationProgress } from "../api/TBIRequests";
import emailAlert from "../images/email-notification.svg";
import theme from "../index.scss";

const Dashboard = ({
  user,
  loadTrackedData,
  loadingTrackedData,
  trackedData,
  errorLoadingTrackedData,
  topSymptoms,
  setTopSymptoms,
  loadingTopSymptoms,
  loadingFeelingToday,
  doneSimpleTracking,
  setDoneSimpleTracking,
  loadingTopTrigger,
  topTrigger,
  errorLoadingTopTrigger,
  loadTrigger,
  messages,
  setTherapistList,
  setCaregiverList,
  setPatientList,
  patientList,
  caregiverList,
  therapistList,
  onOffButtonMessagesStatus,
  setOnOffButtonMessagesStatus,
  inputVisible,
  setInputVisible,
}) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);
  const { dark } = useContext(ThemeContext);

  const [width] = useWindowSize();
  const history = useHistory();
  const location = useLocation();

  // tied to calendar components
  const [date, setDate] = useState(new Date());

  // start with the 0 index of the user's top symptoms and update as needed
  const [currentTrackingSymptom, setCurrentTrackingSymptom] = useState(0);

  // initialized to one because we dont use it until at least one symptom
  // has been tracked
  const [currentTrackingStep, setCurrentTrackingStep] = useState(0);
  const [additionalNotes, setAdditionalNotes] = useState([]);
  const [showPopUp, setShowPopUp] = useState(true);
  const [status, setStatus] = useState("Active");
  const [firstUnfinishedStep, setFirstUnfinishedStep] = useState("");
  const unreadMessagesPresent =
    messages && messages.some((message) => !message.hasBeenRead);
  const [skippedUnloggedSymptoms, setSkippedUnloggedSymptoms] = useState(0);

  //add more symptoms,if there's no tracking factors,then refresh the page
  if (location?.state?.fromPatientDashboard == true) {
    window.location.reload();
  }
  const handleDayClick = (day, { disabled }) => {
    if (disabled) {
      return;
    }
    setDate(day);
  };

  useEffect(() => {
    let isMounted = true; // Add a flag to track if the component is mounted
    // Use the isMounted flag in your asynchronous operation
    getPatientRegistrationProgress(patient?.patientId)
      .then(({ data }) => {
        if (isMounted) {
          // Check if the component is still mounted before updating state
          setFirstUnfinishedStep(data);
        }
      })
      .catch((e) => console.log("getPatientRegistrationProgress", e));

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, [patient]);

  if (patient && firstUnfinishedStep.firstFalseStep != "finished") {
    if (firstUnfinishedStep.firstFalseStep == "step1") {
      history.push("/BasicInfo");
    }
    if (firstUnfinishedStep.firstFalseStep == "step2") {
      if (patient.patientType == "Stroke") {
        history.push("/RecentStroke1", {
          fromPatientControl: true, // add this to skip step7b-Notification preference
        });
      } else if (patient.patientType == "TBI/Concussion") {
        history.push("/RecentTBI1", {
          fromPatientControl: true, // add this to skip step7b-Notification preference
        });
      } else {
        //Dementia and Other
        history.push("/RecentCondition1", {
          fromPatientControl: true, // add this to skip step7b-Notification preference
        });
      }
    }
    if (firstUnfinishedStep.firstFalseStep == "step3") {
      if (patient.patientType == "Stroke") {
        history.push("/RecentStroke2", {
          fromPatientControl: true, // add this to skip step7b-Notification preference
        });
      } else if (patient.patientType == "TBI/Concussion") {
        history.push("/RecentTBI2", {
          fromPatientControl: true, // add this to skip step7b-Notification preference
        });
      } else {
        //Dementia and Other
        history.push("/RecentCondition2", {
          fromPatientControl: true, // add this to skip step7b-Notification preference
        });
      }
    }
    if (firstUnfinishedStep.firstFalseStep == "step4") {
      history.push("/laterSymptoms", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      });
    }
    if (firstUnfinishedStep.firstFalseStep == "step5") {
      history.push("/laterSymptoms", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      }); //not /selectTopSymptoms because it needs info from step4
    }
    if (firstUnfinishedStep.firstFalseStep == "step6") {
      history.push("/selectTrigger", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      });
    }
    if (firstUnfinishedStep.firstFalseStep == "step7") {
      history.push("/selectTherapies", {
        fromPatientControl: true, // add this to skip step7b-Notification preference
      });
    }
  }

  useEffect(() => {
    let isMounted = true; // Add a flag to track if the component is mounted
    // Use the isMounted flag in your asynchronous operation
    getAdditionalNote(patient?.patientId)
      .then(({ data }) => {
        if (isMounted) {
          // Check if the component is still mounted before updating state
          setAdditionalNotes(data);
        }
      })
      .catch((e) => console.log("getAdditionalNotesError", e));

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, [patient]);

  useEffect(() => {
    let isMounted = true; // add this variable to keep track of component's mounted status
    if (topSymptoms && topSymptoms.length > 0 && trackedData) {
      const loggedSymptomToday = getLoggedDataToday(
        trackedData,
        "medical",
        date
      );
      if (loggedSymptomToday.length === 0 && isMounted) {
        // check if component is still mounted before updating state
        setCurrentTrackingSymptom(topSymptoms[0]);
        setCurrentTrackingStep(0);
        setSkippedUnloggedSymptoms(0)
      } else if (isMounted) {
        // check if component is still mounted before calling saveTrackingStep()
        saveTrackingStep();
      }
    }
    // Cleanup function to handle unmounting of the component
    return () => {
      isMounted = false; // update the isMounted variable to reflect that component is unmounted
    };
  }, [history, topSymptoms, trackedData, date]);

  useEffect(() => {
    // TODO: This is a bandaid for resetting on date change, this should be removed in the future. (When doing so, re-add date to the previous useEffect)
    if (topSymptoms && topSymptoms.length > 0 && trackedData) {
      setCurrentTrackingSymptom(topSymptoms[0]);
      setCurrentTrackingStep(0);
      setSkippedUnloggedSymptoms(0);
      prepareNextSymptomTrackingStep(0);
    }
  }, [date]);

  function prepareNextSymptomTrackingStep(skipped) {
    const loggedSymptomToday = getLoggedDataToday(trackedData, "medical", date);

    const unloggedSymptoms = topSymptoms.filter((topSymptom) => {
      return !loggedSymptomToday.some(
        (loggedSymptom) => {
          return loggedSymptom.factor === topSymptom.factor;
        }
      );
    });
    const numLoggedSymptoms = topSymptoms.length - unloggedSymptoms.length

    setSkippedUnloggedSymptoms(skipped)
    setCurrentTrackingStep(numLoggedSymptoms + skipped);

    const nextSymptom = unloggedSymptoms[skipped];
    if (!nextSymptom) {
      finishTrackingStep();
    } else {
      setCurrentTrackingSymptom(nextSymptom);
    }
  }

  const finishTrackingStep = () => {
    const loggedTriggerToday = getLoggedDataToday(trackedData, "SDOH", date);
    if (loggedTriggerToday.length || currentTrackingSymptom === "SDOH") {
      setCurrentTrackingSymptom("done");
    } else {
      setCurrentTrackingSymptom("SDOH");
    }
  };

  const skipTrackingStep = () => {
    prepareNextSymptomTrackingStep(skippedUnloggedSymptoms + 1)
  };

  const saveTrackingStep = () => {
    prepareNextSymptomTrackingStep(skippedUnloggedSymptoms)
  };

  // Extracting properties from the user object
  const { userType, messageRecipient } = user;

  const ConditionalFeelingToday =
    status != "Released" &&
    (userType == "patient" ||
      userType == "Therapist" ||
      userType == "caregiver") &&
    (loadingFeelingToday ? (
      <LoadingSpinner />
    ) : (
      !doneSimpleTracking && (
        <FeelingToday
          setDoneSimpleTracking={setDoneSimpleTracking}
          date={date}
        />
      )
    ));

  const ConditionalSymptomTracker =
    status != "Released" &&
    (userType == "patient" ||
      userType == "Therapist" ||
      userType == "caregiver") &&
    (loadingTopSymptoms || loadingTrackedData || loadingTopTrigger ? (
      <LoadingSpinner />
    ) : (
      <>
        {currentTrackingSymptom === "SDOH" && (
          <ComponentWrapper>
            <TriggerSelector
              topTrigger={topTrigger}
              userType={userType}
              errorLoadingTopTrigger={errorLoadingTopTrigger}
              loadTrigger={loadTrigger}
              date={date}
              skipTrackingStep={skipTrackingStep}
              saveTrackingStep={saveTrackingStep}
              loadTrackedData={loadTrackedData}
            />
          </ComponentWrapper>
        )}
        {currentTrackingSymptom !== "SDOH" &&
          currentTrackingSymptom !== "done" && (
            <ComponentWrapper>
              <SymptomTracker
                currentTrackingStep={currentTrackingStep}
                topSymptoms={topSymptoms}
                date={date}
                user={user}
                symptom={currentTrackingSymptom}
                trackedData={trackedData}
                skipTrackingStep={skipTrackingStep}
                saveTrackingStep={saveTrackingStep}
                loadTrackedData={loadTrackedData}
              />
            </ComponentWrapper>
          )}
        {currentTrackingSymptom === "done" && (
          <ComponentWrapper>
            <AdditionalNote date={date} />
          </ComponentWrapper>
        )}
      </>
    ));

  const ConditionalAddSymptoms = topSymptoms &&
    topSymptoms.length > 0 &&
    status != "Released" &&
    (userType == "patient" ||
      userType == "Therapist" ||
      userType == "caregiver") && (
      <AddSymptoms topSymptoms={topSymptoms} setTopSymptoms={setTopSymptoms} />
    );

  const ConditionalCombinedChart =
    user.noPatientProvider !== "noPatient" &&
    (loadingTrackedData || loadingTopSymptoms ? (
      <LoadingSpinner />
    ) : (
      <Charts
        topSymptoms={topSymptoms}
        data={trackedData}
        error={errorLoadingTrackedData}
        retry={loadTrackedData}
        additionalNotes={additionalNotes}
        user={user}
      />
    ));

  const ConditionalCurrentPatient = () =>
    user.noPatientProvider !== "noPatient" &&
    (userType === "caregiver" ||
      userType === "provider" ||
      userType === "Therapist") && (
      <Col md={3}>
        <CurrentPatient userType={userType} />
      </Col>
    );

  const ConditionalInviteProvider = () =>
    userType !== "provider" && (
      <InputVisibleContext.Provider value={{ inputVisible }}>
        <Col md={3}>
          <InviteProvider user={user} setInputVisible={setInputVisible} />
        </Col>
      </InputVisibleContext.Provider>
    );

  const ConditionalInviteTherapist = () =>
    userType !== "Therapist" && (
      <Col md={3}>
        <InviteTherapist user={user} />
      </Col>
    );

  const ConditionalInvitePatient = () =>
    (userType === "Therapist" || userType === "provider") && (
      <Col md={3}>
        <InvitePatient user={user} />
      </Col>
    );

  const ConditionalReferPatientButton = () =>
    user.noPatientProvider !== "noPatient" &&
    (userType === "provider" || userType === "Therapist") && (
      <Col md={3}>
        <ReferPatientButton setInputVisible={setInputVisible} />
      </Col>
    );

  const ConditionalOnOffButtonMessages = () =>
    (userType === "Therapist" || userType === "provider") && (
      <OnOffButtonMessages
        onTitle={TOGGLE_MESSAGES[language].on}
        offTitle={TOGGLE_MESSAGES[language].off}
        caregiverList={caregiverList}
        therapistList={therapistList}
        // setHideForProviderThatTurnMessageOff={setHideForProviderThatTurnMessageOff}
        onOffButtonMessagesStatus={onOffButtonMessagesStatus}
        setOnOffButtonMessagesStatus={setOnOffButtonMessagesStatus}
      />
    );

  const ConditionalOnOffButtonAlert = () =>
    (userType === "Therapist" || userType === "provider") && (
      <OnOffButtonAlert
        onTitle={TOGGLE_ALERTS[language].on}
        offTitle={TOGGLE_ALERTS[language].off}
      />
    );

  const ConditionalOnOffButtonReferralRecord = () =>
    (userType === "provider" || userType === "Therapist") && (
      <OnOffButtonReferralRecord />
    );

  const ConditionalPatientStatus = () =>
    user.noPatientProvider !== "noPatient" &&
    (userType === "provider" || userType === "Therapist") && (
      <PatientStatus
        status={status}
        setStatus={setStatus}
        userType={userType}
        patientId={patient?.patientId}
        therapistList={therapistList}
        patientList={patientList}
        caregiverList={caregiverList}
        setTherapistList={setTherapistList}
        setCaregiverList={setCaregiverList}
        setPatientList={setPatientList}
      />
    );

  const ConditionalCalendar = () => {
    if (userType !== "provider") {
      return <Calendar selectedDays={date} onDayClick={handleDayClick} />;
    }
    return null;
  };

  const ConditionalSendMessage = () =>
    messageRecipient?.length !== 0 && (
      <div className="d-flex justify-content-center pt-2 pb-2 pl-1 pr-1">
        <h6
          className="pt-2 pb-2 pl-3 pr-3"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            backgroundColor: dark
              ? theme.darkModePrimary
              : unreadMessagesPresent
                ? "#3EB1A6"
                : "#ef5866",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() =>
            history.push("/messages", {
              curPatient: patient,
            })
          }
        >
          {unreadMessagesPresent ? (
            <div>
              {MAILBOX[language].new}
              <img
                style={{
                  maxHeight: "30px",
                  maxWidth: "30px",
                  display: "inline-block",
                  paddingLeft: 10,
                }}
                src={emailAlert}
                alt="email alert"
              />
            </div>
          ) : (
            MAILBOX[language].send_or_read
          )}
        </h6>
      </div>
    );

  const ConditionalSendMessagePatient = () =>
    messageRecipient?.length !== 0 && (
      <Col md={3}>
        <div className="d-flex justify-content-center">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "75%",
              height: "100%",
              borderRadius: "20px",
              backgroundColor: dark
                ? theme.darkModePrimary
                : unreadMessagesPresent
                  ? "#3EB1A6"
                  : "#ef5866",
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={() =>
              history.push("/messages", {
                curPatient: patient,
              })
            }
          >
            {unreadMessagesPresent ? (
              <div>
                {MAILBOX[language].new}
                <img
                  style={{
                    maxHeight: "30px",
                    maxWidth: "30px",
                    display: "inline-block",
                    paddingLeft: 10,
                  }}
                  src={emailAlert}
                  alt="email alert"
                />
              </div>
            ) : (
              MAILBOX[language].send_or_read
            )}
          </h6>
        </div>
      </Col>
    );

  const ConditionalTbiSurvey = () => {
    const handleOpenNewTab = () => {
      const url =
        "https://forms.office.com/pages/responsepage.aspx?id=gxBGbdm66ECZbn5psfLnRwMgEdq_7OJJhXTsyptmlEdUN0lSQ09KSlVKMFg1QTBLNUZGVjI1VUFDTS4u";
      window.open(url, "_blank");
    };
    return (
      <Col md={3}>
        <div className="d-flex justify-content-center tooltip-container">
          <h6
            className="pt-2 pb-2 pl-3 pr-3"
            style={{
              width: "75%",
              height: "100%",
              borderRadius: "20px",
              backgroundColor: dark ? theme.darkModePrimary : "#7eaa54",
              color: dark ? theme.darkModeText : "white",
              textAlign: "center",
              overflowWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={handleOpenNewTab}
          >
            Patient Summit Registration
          </h6>
          <span className="tooltip-text">
            Help us calculate the cost of your injury
          </span>
        </div>
      </Col>
    );
  };

  const VerifyEmailMessage =
    user.isVerified === false && user.userType !== "provider" && showPopUp ? (
      <Alert
        variant="warning"
        onClose={() => {
          setShowPopUp(false);
        }}
        dismissible
      >
        <Alert.Heading>{VERIFY_EMAIL[language].header}</Alert.Heading>
        <p>{VERIFY_EMAIL[language].body[0]}</p>
        <p>
          {`${VERIFY_EMAIL[language].body[1]} ${user.email}${VERIFY_EMAIL[language].body[2]}`}
        </p>
        {/* Please verify your email address to access all of dashboard's
            features. We have sent an email to {user.email}. */}
      </Alert>
    ) : null;

  const getPossibleMessages = () => ({
    sallieText: {
      Stroke: SALLIE_DASHBOARD[language].patient,
      tbiPatient: SALLIE_DASHBOARD[language].patient,
      caregiver: SALLIE_DASHBOARD[language].non_patient,
      // For this to work properly you need to track " +
      // `${patient && patient.firstName}'s` +
      // " symptoms. Begin tracking below
      provider: SALLIE_DASHBOARD[language].non_patient,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  if (width >= 768) {
    return (
      <>
        {VerifyEmailMessage}
        <Row>
          <Col md={9}>
            {user.userType === "provider" && (
              <Sallie
                user={user}
                text={getMessage("sallieText")}
                isProvider={true}
              />
            )}
            {(user.userType === "caregiver" ||
              user.userType === "Therapist") && (
                <Sallie text={getMessage("sallieText")} isTPCG={true} />
              )}
            {user.userType !== "provider" &&
              user.userType !== "caregiver" &&
              user.userType !== "Therapist" && (
                <Sallie text={getMessage("sallieText")} />
              )}
          </Col>
          <Col>
            <ThemeChanger />
          </Col>
        </Row>
        {/* End top row */}
        <Row className="d-flex align-items-start justify-content-between ">
          {ConditionalCurrentPatient()}
          {ConditionalReferPatientButton()}
          {ConditionalInviteTherapist()}
          {ConditionalInvitePatient()}
          {ConditionalInviteProvider()}
          {user.userType == "patient" && ConditionalSendMessagePatient()}
          {/* {(user.userType == "patient" || user.userType == "caregiver") && ConditionalTbiSurvey()} */}
        </Row>

        <Row>
          {/* One shorter left col and one larger right col */}
          <Col md={4}>
            {user.noPatientProvider !== "noPatient" && (
              <GoalList userType={user.userType} />
            )}
            {user.userType != "patient" && ConditionalSendMessage()}
            {user.userType != "provider" && <EditNotification />}
            <ConditionalCalendar />
            <div className="mt-3">
              {user.noPatientProvider !== "noPatient" && patient && (
                <DaysFromInjury patient={patient} />
              )}
              {ConditionalOnOffButtonMessages()}
              {ConditionalOnOffButtonAlert()}
              {ConditionalOnOffButtonReferralRecord()}
              {ConditionalPatientStatus()}
            </div>
          </Col>
          <Col md={8}>
            {ConditionalFeelingToday}
            {ConditionalSymptomTracker}
            {ConditionalAddSymptoms}
            {ConditionalCombinedChart}
            <SuggestionBox />
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        {VerifyEmailMessage}
        <Row>
          <Col md={8}>
            {userType === "provider" && (
              <Sallie text={getMessage("sallieText")} isProvider={true} />
            )}
            {(userType === "caregiver" || userType === "Therapist") && (
              <Sallie text={getMessage("sallieText")} isTPCG={true} />
            )}
            {!(
              userType === "provider" ||
              userType === "caregiver" ||
              userType === "Therapist"
            ) && <Sallie text={getMessage("sallieText")} />}
          </Col>
          <Col>
            <ThemeChanger />
          </Col>
        </Row>
        {/* End top row */}
        <Row className="d-flex align-items-center justify-content-center">
          {ConditionalCurrentPatient()}
          {ConditionalInviteProvider()}
          {ConditionalInvitePatient()}
          {ConditionalInviteTherapist()}
          {/* {(user.userType == "patient" || user.userType == "caregiver") && ConditionalTbiSurvey()} */}
          {ConditionalReferPatientButton()}
        </Row>
        <Row>
          {/* One shorter left col and one larger right col */}
          <Col md={4}>
            {user.noPatientProvider !== "noPatient" && (
              <GoalList userType={user.userType} />
            )}
            {user.userType != "provider" && <EditNotification />}
            <ConditionalCalendar />
            {user.noPatientProvider !== "noPatient" && patient && (
              <DaysFromInjury patient={patient} />
            )}
            {ConditionalSendMessage()}
            {ConditionalOnOffButtonMessages()}
            {ConditionalOnOffButtonAlert()}
            {ConditionalOnOffButtonReferralRecord()}
            {ConditionalPatientStatus()}
          </Col>

          <Col md={8}>
            {ConditionalFeelingToday}
            {ConditionalSymptomTracker}
            {ConditionalAddSymptoms}
            {ConditionalCombinedChart}
            <SuggestionBox />
          </Col>
        </Row>
      </>
    );
  }
};

export default Dashboard;
